import localAppTheme from '../defaults/theme.default.json'
import { getThemeData, mergeThemes, getMenuButtonStyle } from '../utils/contentfulThemeMerger'
import { fetchNaviagationConfig } from './navigation.action'
import { fetchSearchConfig } from '../actions/search.action'
import { features as featuresConfig } from '../actions/features'
import { fetchAnalyticsConfig } from '../actions/analytics.action'
import { filter } from '../utils/contentfulHandler'
import utils from '../utils/utils'
import { setGraphics } from '../utils/localStorageService'
import {
  APP_THEME_CONFIG, COMPONENT_THEME_CONFIG, POPUP_THEME_CONFIG,
  META_THEME_CONFIG, FOOTER_LIST, MAX_VISIBLE_MENUITEM, SPECIAL_LIST,
  SUPPORT_PAGE_THEME_CONFIG, PLAYER_THEME_CONFIG, INTRO_THEME_CONFIG, INTRO_THEME_HEADER_CONFIG
} from '../constants/actionTypes'

/** ThemeConfig store */
export const themeConfig = (options, application) => (dispatch) => {
  let appThemeData = localAppTheme
  let popupThemeData = {}
  let componentThemeData = {}

  const {
    defaultPageTheme = [],
    defaultComponentTheme = [],
    defaultPopupTheme = [],
    navigation = [],
    features = []
  } = application[0] || {}

  appThemeData = localAppTheme
  if (defaultPageTheme.length > 0) {
    const defaultTheme = filter(defaultPageTheme, '', options)
    if (defaultTheme.length > 0) {
      appThemeData = getThemeData(defaultTheme[0], options)
      appThemeData = mergeThemes(localAppTheme, appThemeData)
    }
  }
  dispatch({
    type: APP_THEME_CONFIG,
    value: appThemeData
  })

  const { graphics } = appThemeData
  if (graphics) setGraphics(graphics)

  componentThemeData = appThemeData
  if (defaultComponentTheme.length > 0) {
    const defaultTheme = filter(defaultComponentTheme, '', options)
    if (defaultTheme.length > 0) {
      componentThemeData = getThemeData(defaultTheme[0], options)
      componentThemeData = mergeThemes(appThemeData, componentThemeData)
    }
  }
  dispatch({
    type: COMPONENT_THEME_CONFIG,
    value: componentThemeData
  })

  popupThemeData = appThemeData
  if (defaultPopupTheme.length > 0) {
    const defaultTheme = filter(defaultPopupTheme, '', options)
    if (defaultTheme.length > 0) {
      popupThemeData = getThemeData(defaultTheme[0], options)
      popupThemeData = mergeThemes(appThemeData, popupThemeData)
    }
  }
  dispatch({
    type: POPUP_THEME_CONFIG,
    value: popupThemeData
  })

  const footerList = []
  const specialList = []
  let maxVisibleItem = 1
  let navigationPromise = null
  if (navigation.length > 0) {
    // navigation = filter(navigation)
    navigationPromise = dispatch(fetchNaviagationConfig(navigation, { appThemeData, componentThemeData }, options))

    const { maxVisibleItems, menuItems = [] } = navigation[0]
    maxVisibleItem = maxVisibleItems

    let menu = menuItems
    menu = filter(menu, '', options)
    menu.map((menuItem) => {
      const { title, page = [], itemStyle, id, buttonStyle } = menuItem
      let menuButtonStyle = {}
      if (buttonStyle) {
        const buttonStyleData = getMenuButtonStyle(buttonStyle)
        menuButtonStyle = buttonStyleData
      }
      let pageFilter = filter(page, '', options)
      if (pageFilter.length > 0) {
        pageFilter = pageFilter[0]
      } else {
        pageFilter = {}
      }
      const { type, identifierExt, theme } = pageFilter
      if (itemStyle === 'footer') {
        footerList.push({ title, identifierExt, type })
      } else if (itemStyle === 'special') {
        specialList.push({ title, id, type, theme, menuButtonStyle })
      }
      return null
    }).filter(data => data)
  }
  dispatch({
    type: FOOTER_LIST,
    value: footerList
  })
  dispatch({
    type: SPECIAL_LIST,
    value: specialList
  })
  dispatch({
    type: MAX_VISIBLE_MENUITEM,
    value: maxVisibleItem
  })

  const supportInformationArray = features.filter(item => {
    if (item.contentTypeId === 'featureSupport') return item
    return null
  })

  let supportInformationTheme = appThemeData
  if (utils.isNotNull(supportInformationArray, 0, 'theme')) {
    if (supportInformationArray[0].theme.length > 0) {
      const defaultTheme = filter(supportInformationArray[0].theme, '', options)
      if (defaultTheme.length > 0) {
        supportInformationTheme = getThemeData(defaultTheme[0], options)
        supportInformationTheme = mergeThemes(appThemeData, supportInformationTheme)
      }
    }
  }
  dispatch({
    type: SUPPORT_PAGE_THEME_CONFIG,
    value: supportInformationTheme
  })

  const playerFeature = features.filter(item => {
    if (item.contentTypeId === 'featurePlayer') return item
    return null
  })

  let fullScreenThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'fullScreenConfig', 0, 'theme')) {
    if (playerFeature[0].fullScreenConfig[0].theme.length > 0) {
      const defaultTheme = filter(playerFeature[0].fullScreenConfig[0].theme, '', options)
      if (defaultTheme.length > 0) {
        fullScreenThemeData = getThemeData(defaultTheme[0], options)
        fullScreenThemeData = mergeThemes(appThemeData, fullScreenThemeData)
      }
    }
  }

  let linearPlayerThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'linearPlayerConfig', 0, 'theme')) {
    if (playerFeature[0].linearPlayerConfig[0].theme.length > 0) {
      const defaultTheme = filter(playerFeature[0].linearPlayerConfig[0].theme, '', options)
      if (defaultTheme.length > 0) {
        linearPlayerThemeData = getThemeData(defaultTheme[0], options)
        linearPlayerThemeData = mergeThemes(appThemeData, linearPlayerThemeData)
      }
    }
  }

  let portraitThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'portraitConfig', 0, 'theme')) {
    if (playerFeature[0].portraitConfig[0].theme.length > 0) {
      const defaultTheme = filter(playerFeature[0].portraitConfig[0].theme, '', options)
      if (defaultTheme.length > 0) {
        portraitThemeData = getThemeData(defaultTheme[0], options)
        portraitThemeData = mergeThemes(appThemeData, portraitThemeData)
      }
    }
  }

  let castPlayerThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'castConfig', 0, 'theme')) {
    if (playerFeature[0].castConfig[0].theme.length > 0) {
      let defaultTheme = filter(playerFeature[0].castConfig[0].theme, '', options)
      if (defaultTheme.length > 0) {
        castPlayerThemeData = getThemeData(defaultTheme[0], options)
        castPlayerThemeData = mergeThemes(appThemeData, castPlayerThemeData)
      }
    }
  }

  let castMiniThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'castConfig', 0, 'controllerTheme')) {
    if (playerFeature[0].castConfig[0].controllerTheme.length > 0) {
      const defaultminiTheme = filter(playerFeature[0].castConfig[0].controllerTheme, '', options)
      if (defaultminiTheme.length > 0) {
        castMiniThemeData = getThemeData(defaultminiTheme[0], options)
        castMiniThemeData = mergeThemes(appThemeData, castMiniThemeData)
      }
    }
  }

  let castSelectorThemeData = appThemeData
  if (utils.isNotNull(playerFeature, 0, 'castConfig', 0, 'selectorTheme')) {
    if (playerFeature[0].castConfig[0].selectorTheme.length > 0) {
      const defaultselectorTheme = filter(playerFeature[0].castConfig[0].selectorTheme, '', options)
      if (defaultselectorTheme.length > 0) {
        castSelectorThemeData = getThemeData(defaultselectorTheme[0], options)
        castSelectorThemeData = mergeThemes(appThemeData, castSelectorThemeData)
      }
    }
  }
  dispatch({
    type: PLAYER_THEME_CONFIG,
    value: {
      fullScreenTheme: fullScreenThemeData,
      linearPlayerTheme: linearPlayerThemeData,
      portraitTheme: portraitThemeData,
      castPlayerTheme: castPlayerThemeData,
      castMiniTheme: castMiniThemeData,
      castSelectorTheme: castSelectorThemeData
    }
  })

  const featureCatalogArray = features.filter(item => {
    if (item.contentTypeId === 'featureCatalog') return item
    return null
  })

  let metaTheme = appThemeData
  if (utils.isNotNull(featureCatalogArray, 0, 'metaTheme')) {
    if (featureCatalogArray[0].metaTheme.length > 0) {
      const defaultTheme = filter(featureCatalogArray[0].metaTheme, '', options)
      if (defaultTheme.length > 0) {
        metaTheme = getThemeData(defaultTheme[0], options)
        metaTheme = mergeThemes(appThemeData, metaTheme)
      }
    }
  }
  dispatch({
    type: META_THEME_CONFIG,
    value: metaTheme
  })


  const featureIntroArray = features.filter(item => {
    if (item.contentTypeId === "featureIntro") return item
    return null
  })


  let featureIntroTheme = appThemeData
  if (utils.isNotNull(featureIntroArray, 0, 'theme')) {
    if (featureIntroArray[0].theme.length > 0) {
      const defaultTheme = filter(featureIntroArray[0].theme, '', options)
      if (defaultTheme.length > 0) {
        featureIntroTheme = getThemeData(defaultTheme[0], options)
        featureIntroTheme = mergeThemes(appThemeData, featureIntroTheme)
      }
    }
  }

  dispatch({
    type: INTRO_THEME_CONFIG,
    value: featureIntroTheme
  })

  let featureIntroHeaderTheme = appThemeData
  if (utils.isNotNull(featureIntroArray, 0, 'landingPage')) {
    if (featureIntroArray[0].landingPage.length > 0) {
      if (featureIntroArray[0].landingPage[0]?.theme?.length > 0) {
        const defaultTheme = filter(featureIntroArray[0].landingPage[0]?.theme, '', options)
        if (defaultTheme.length > 0) {
          featureIntroHeaderTheme = getThemeData(defaultTheme[0], options)
          featureIntroHeaderTheme = mergeThemes(appThemeData, featureIntroHeaderTheme)
        }
      }
    }
  }
  
  dispatch({
    type: INTRO_THEME_HEADER_CONFIG,
    value: featureIntroHeaderTheme
  })

  const configPromise = dispatch(featuresConfig(features, { appThemeData, popupThemeData, componentThemeData }, options))
  const analyticsPromise = dispatch(fetchAnalyticsConfig(features, options))
  const searchPromise = dispatch(fetchSearchConfig(features, { appThemeData, componentThemeData }, options))

  return Promise.all([navigationPromise, configPromise, analyticsPromise, searchPromise])
}
