import styled from 'styled-components'
// Styled components used in DetailsSection component.
/* --------------------------------------------- */
export const DetailsSectionWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
  color: ${({ theme: { body } }) => body && body.text.primary};

  .details-section__more-info-link {
    color: ${({ theme: { body } }) => body && body.accent.primary};
  }

  .details-section__play-button{
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #FFE300;
    padding: 10px 5px;
    color: #FFE300;
    width: 100px;
    text-align: center;
    font-weight: 900;

    :hover{
      background-color: #FFE300;
      color: black;
    }
  }
`

export const DetailsPopup = styled.div`
  background: ${({ theme: { body } }) => body && body?.background?.primary};
  .normal {
    background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.normal?.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.normal?.text};
    border: 1px solid ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.normal?.stroke};
    :hover {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.focussed?.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.focussed?.text};
      border: 1px solid ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.secondaryButton?.focussed?.stroke};
    }
  }
`;

export const BodyTextSecondary = styled.p`
color: ${({ metaTheme: { header } }) => header && header.text.secondary};
`

export const DetailsPagePopupHeader = styled.p`
color: ${({ metaTheme: { header } }) => header && header.text.primary};
`

export const DetailsSectionSubtitle = styled.div`
  color: ${({ metaTheme: { body } }) => body && body.text.secondary};
`

export const DetailsSectionSubtitleMovie = styled.div`
  color: ${({ metaTheme: { body } }) => body && body.text.secondary};
`

export const MetaBodyTextPrimaryH2 = styled.h2`
  color: ${({ metaTheme: { body } }) => body && body.text.primary};
`

export const DetailsSectionTitle = styled.h1`
  color: ${({ metaTheme: { header } }) => header && header.text.primary};
`

export const DetailsPageBadges = styled.ul`
  & p, & span{
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
  }

  & li {
    border: solid 2px ${({ metaTheme: { header } }) => header && header.text.tertiary};
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
    display:flex;
    justify-content:center;
    align-items:center
  }

  & .filled {
    background: ${({ metaTheme: { header } }) => header && header.text.tertiary};
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
  }
`

export const PlayBtnAction = styled.div`
  border: solid 1px ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.stroke};
  & p {
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.text};
  }
  &:hover{
    background-color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.background};
    & p {
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.text};
  }
  }
`

export const DetailsSectionMetaTitle = styled.div`
  color: ${({ theme: { body } }) => body && body.text.primary};
`

export const MetaBodyTextSecondary = styled.div`
  color: ${({ metaTheme: { body } }) => body && body.text.secondary};
`

export const MetaBodyTextTertiary = styled.div`
  color: ${({ metaTheme: { body } }) => body && body.text.tertiary};
  a {
    color: ${({ metaTheme: { body } }) => body && body.text.tertiary};
  }

  a:hover {
    color: ${({ metaTheme: { body } }) => body && body.accent.primary};
  }
`

export const SeasonSelector = styled.div`
  & li {
    background-color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.normal.background};
    color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.normal.text};
  }

  & li:hover{
    background-color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.background};
    color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.text};
  }
  & .active{
    background-color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selected.background};
    color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selected.text};
  }

  & .active:hover{
    background-color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selectedFocussed.background};
    color:${({ metaTheme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selectedFocussed.text};
  }
`

export const MetaBodyTextTertiaryP = styled.p`
  color: ${({ metaTheme: { body } }) => body && body.text.tertiary};
`

export const DetailsSectionMetaValue = styled.div`
  color: ${({ theme: { body } }) => body && body.text.tertiary};
`

export const DetailsSectionDescription = styled.div`
  color: ${({ theme: { body } }) => body && body.text.tertiary};
`
